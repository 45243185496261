
 @font-face {
  font-family: 'Hellix';
  src: url('../assets/fonts/Hellix-Bold.eot');
  src: url('../assets/fonts/Hellix-Bold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Hellix-Bold.woff2') format('woff2'),
      url('../assets/fonts/Hellix-Bold.woff') format('woff'),
      url('../assets/fonts/Hellix-Bold.ttf') format('truetype'),
      url('../assets/fonts/Hellix-Bold.svg#Hellix-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('../assets/fonts/Hellix-ExtraBold.eot');
  src: url('../assets/fonts/Hellix-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Hellix-ExtraBold.woff2') format('woff2'),
      url('../assets/fonts/Hellix-ExtraBold.woff') format('woff'),
      url('../assets/fonts/Hellix-ExtraBold.ttf') format('truetype'),
      url('../assets/fonts/Hellix-ExtraBold.svg#Hellix-ExtraBold') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Hellix';
  src: url('../assets/fonts/Hellix-Medium.eot');
  src: url('../assets/fonts/Hellix-Medium.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Hellix-Medium.woff2') format('woff2'),
      url('../assets/fonts/Hellix-Medium.woff') format('woff'),
      url('../assets/fonts/Hellix-Medium.ttf') format('truetype'),
      url('../assets/fonts/Hellix-Medium.svg#Hellix-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('../assets/fonts/Hellix-Regular.eot');
  src: url('../assets/fonts/Hellix-Regular.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Hellix-Regular.woff2') format('woff2'),
      url('../assets/fonts/Hellix-Regular.woff') format('woff'),
      url('../assets/fonts/Hellix-Regular.ttf') format('truetype'),
      url('../assets/fonts/Hellix-Regular.svg#Hellix-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica Bold.ttf');
  src: url('../assets/fonts/Helvetica-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica-Normal.ttf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../assets/fonts/Helvetica-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Hellix';
  src: url('../assets/fonts/Hellix-SemiBold.eot');
  src: url('../assets/fonts/Hellix-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('../assets/fonts/Hellix-SemiBold.woff2') format('woff2'),
      url('../assets/fonts/Hellix-SemiBold.woff') format('woff'),
      url('../assets/fonts/Hellix-SemiBold.ttf') format('truetype'),
      url('../assets/fonts/Hellix-SemiBold.svg#Hellix-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Microsoft YaHei';
  src: 
      url('../assets/fonts/Microsoft-YaHei.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Noto Sans SC';
  src: url('../assets/fonts/NotoSansSC-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans SC';
  src: url('../assets/fonts/NotoSansSC-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans KR';
  src: url('../assets/fonts/NotoSansKR-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}


*{
  font-family: 'Hellix','Helvetica' 
}

html,
body {
 background-color: #fff !important;
 -webkit-font-smoothing: antialiased;
 
}
.MuiButton-containedSizeLarge{
  display: none;
}

.disable-scroll {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  height: 100vh !important;
  overflow: hidden !important;
  pointer-events: none;
}

.MuiModal-root {
  z-index: 9999999999999 !important;
}
@media screen and (min-width: 1200px) {
  .home_page_store .MuiContainer-root {
    max-width: 1164px !important ;
  }
}

@media screen and (max-width: 992px) {
  .home_page_store .MuiContainer-root {
    max-width: 960px !important ;
  }
}

@media screen and (max-width: 768px) {
  .home_page_store .MuiContainer-root {
    max-width: 720px !important ;
  }
}
